import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import { getFormattedBusinessLocationAddress } from '../utils/formatting';
import { mediaUrl } from '../utils/constants';

const useStyles = makeStyles({
    businessLocationCard: {
        margin: '20px 20px',
        boxShadow: '0 2px 4px 2px rgba(0,0,0,0.19)',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 2px 8px 2px rgba(0,0,0,0.35)'
        }
    },
    businessLocationCardTitle: {
        fontSize: 17,
        fontWeight: '500'
    }
});

function BusinessLocation(props) {
    let data = props.data;
    const classes = useStyles();
    return (
        <Card className={classes.businessLocationCard} onClick={() => props.handleClick(data.business_location_id)}>
            <CardMedia
                component="img"
                alt={data.business_location_name}
                height="100"
                image={`${mediaUrl}${data.business_location_img ? data.business_location_img : props.businessImg}`}
                title={data.business_location_name}
            />
            <CardContent>
                <Typography className={classes.businessLocationCardTitle} gutterBottom component="h2">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{data.business_location_name}</div>
                        <div>{props.selected ? <FiCheck color={'#4caf50'}/> : <FiArrowRight/>}</div>
                    </Box>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {getFormattedBusinessLocationAddress(data)}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default BusinessLocation;